.sidebar-wrapper-floating {
    position: absolute;
}


.sidebar-wrapper.close {
    overflow: visible;
}

.sidebar-wrapper {
    background-color: var(--white);
    overflow: hidden;
    min-height: 100%;
    min-width: var(--sidebar-width);
    width: var(--sidebar-width);
    margin-left: 0;
    border-right: 1px solid var(--secondary-color);
    transition: var(--nav-animation);
}

.sidebar-wrapper.close {
    min-width: var(--animated-width);
    width: var(--animated-width);
}


.logo-wrapper {

    padding: 1.15rem;
    width: var(--sidebar-width);
    text-align: center;
    overflow: hidden;
    transition: var(--nav-animation);
    background:  var(--primary-color);
}

.logo-wrapper.close {
    width: var(--animated-width);
    overflow: hidden;
}


.logo-wrapper.close>.logo-img {
    width: 256px;
    min-width: 256px;
    display: block;
    overflow: hidden;
}


.logo-wrapper>.logo-img {
    width: 256px;
    min-width: 256px;
    display: block;
}

.logo-wrapper.close::after {
    left: calc(var(--animated-width) - 1px);
}

.logo-wrapper::after {
    content: '';
    width: 1px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: var(--nav-animation);
    left: calc(var(--sidebar-width) - 1px);
    border-right: 1px solid var(--secondary-color);
}
