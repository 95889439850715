.btnWrapper {}

.ctrButton {
    font-size: 1rem;
    background-color: var(--primary-color);
}

.btn-primary,
.btn-secondary {
    transition: all 0.2s linear;
}

.btn-primary.ctrButton:hover,
.btn-primary.ctrButton:active,
.btn-primary.ctrButton:focus,
.btn-primary.ctrButton:focus-visible {
    cursor: pointer;
    background-color: var(--primary-dark-color);
    box-shadow: none;
}

.btn-secondary.ctrButton:hover,
.btn-secondary.ctrButton:active,
.btn-secondary.ctrButton:focus,
.btn-secondary.ctrButton:focus-visible {
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 1px var(--secondary-color);
}

.btn-secondary {
    background-color: #fff;
    box-shadow: inset 0px 0px 0px 1px var(--primary-color);
    color: var(--primary-color);
}