.pages-wrapper {
    background-color: var(--bg-color);
}

.page {
   
}

.boxed {
    border: 1px solid var(--light);
}