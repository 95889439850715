.lblWrapper {
    display: flex;
    align-items: center;
    margin-right: 0.7rem;
}

.ctrLabel {
    color: var(--label-color);
    font-weight: bold;
    font-size: 1.0rem;
}
