.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleWrapper>h1,
.titleWrapper>h2,
.titleWrapper>h3,
.titleWrapper>h4,
.titleWrapper>h5,
.titleWrapper>h6 {
    font-weight: bold;
    color: var(--dark);
}

.titleWrapper>h1 {
    font-size: 2.2rem;
}

.titleWrapper>h2 {
    font-size: 2rem;
}

.titleWrapper>h3 {
    font-size: 1.8rem;
}

.titleWrapper>h4 {
    font-size: 1.6rem;
}

.titleWrapper>h5 {
    font-size: 1.4rem;
}

.titleWrapper>h6 {
    font-size: 1.2rem;
}

.boxed .titleWrapper {
    border: none;
    border-bottom: 1px solid var(--light);
}