.header-wrapper {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    min-height: var(--min-header-height);
    align-items: center;
    position: relative;
}

.header-elements {
    flex: 1;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 0.5rem;
}