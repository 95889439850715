.txtWrapper {
    padding: 0;
    display: flex;
}

.lbl-left {
    flex-direction: row;
    justify-content: center;
}

.lbl-top {
    flex-direction: column;
    justify-content: start;
}

.ctrTextBox {
    position: relative;
    color: #000;
    border: 1px solid var(--control-border);
}

.ctrTextBox:focus,
.ctrTextBox:focus-within,
.ctrTextBox:active {
    border: 1px solid var(--primary-color) !important;
    outline: none;
}

.txt-div {
    position: relative;
    padding-right: 30px;
}

.txt-div:after {
    position: absolute;
    content: '';
    top: 50%;
    right: 10px;
    z-index: 2;
    display: block;
    border: solid var(--dark);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
}