
.login-page-sec {
  background: url(../../public/login.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 64px);

  .login-sec-main-view {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 99%;
    padding-bottom: 64px;

    .login-main-bg{
        padding: 4% 8% 6%;
    }
  }
}
.account-list-tble{
    .table-main thead{
        tr{
            th{
                padding: 15px;
                &:nth-child(1){
                    width: 35%;
                }
                &:nth-child(2){
                    width: 25%;
                }
                &:nth-child(3){
                    width: 25%;
                }
                &:nth-child(4){
                    width: 25%;
                }
            }            
            td{
                padding: 15px;
                &:nth-child(1){
                    width: 35%;
                }
                &:nth-child(2){
                    width: 25%;
                }
                &:nth-child(3){
                    width: 25%;
                }
                &:nth-child(4){
                    width: 25%;
                }
            }
        }


    }
}


.partner-tbl{
    .table-main {
        thead{
        tr{
            th{
                text-align: left;
                padding: 25px 15px;
                &:nth-child(1){
                    width: 10%;
                }
                &:nth-child(2){
                    width: 20%;
                }
                &:nth-child(3){
                    width: 20%;
                    word-wrap: break-word;
                    white-space: break-spaces;
                    max-width: 250px;
                }
                &:nth-child(4){
                    width: 15%;
                    text-align: center;
                }
                &:nth-child(5){
                    width: 11%;
                    text-align: center;
                }
                &:nth-child(6){
                    width: 7%;
                }
                &:nth-child(7){
                    width: 7%;
                    text-align: center;
                }
                &:nth-child(8){
                    width: 10%;
                    text-align: center;
                }
            }   
        }
    }
        tbody{ 
            tr{        
            td{
                padding: 10px 15px;
                text-align: left;
                &:nth-child(1){
                    width: 10%;
                }
                &:nth-child(2){
                    width: 20%;
                }
                &:nth-child(3){
                    width: 20%;    
                    max-width: 250px;
                    word-wrap: break-word;
                    white-space: break-spaces;             
                }
                &:nth-child(4){
                    width: 15%;
                    text-align: center;
                }
                &:nth-child(5){
                    width: 11%;
                    text-align: center;
                }
                &:nth-child(6){
                    width: 7%;
                }
                &:nth-child(7){
                    width: 7%;
                    text-align: center;
                }
                &:nth-child(8){
                    width: 10%;
                    text-align: center;
                }
            }
        }
    }
    


    }
}

