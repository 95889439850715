:root {
    --primary-color: #0e61b4;
    --secondary-color: #abc1d6;
    --bg-color: #fff;
    --primary-light-color: #2c80d4;
    --primary-dark-color: #0a5096;
    --secondary-light-color: #f00;
    --light: #e5e5e5;
    --dark: #6e6e6e;
    --transperency: 0.7;
    --min-header-height: 90px;
    --min-footer-height: 70px;
    --sidebar-width: 300px;
    --animated-width: 70px;
    --control-border: #9ca1aa;
    --label-color: #6e6e6e;
    --nav-animation: all 0.2s linear;
}

body, html {
    background-color: var(--bg-color);
    min-height: 100vh;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    min-height: inherit;
}