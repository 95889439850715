@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6e6e6e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
input,
optgroup,
select,
textarea,
.css-1lb4uql-control,
.css-12zf3ro-control {
  height: 55px;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
  height: 55px;
}

.text-2xl,
.text-xl .text-lg,
.text-sm {
  letter-spacing: normal !important;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: unset !important;
  outline-offset: unset !important;
  --tw-ring-inset: unset !important;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: unset !important;
  --tw-ring-offset-shadow: unset !important;
  --tw-ring-shadow: unset !important;
  box-shadow: unset !important;
  border-color: unset !important;
}

a {
  transition: 0.5s;
  font-weight: 500;
}

a:hover {
  color: #0e61b4;
  transition: 0.5s;
  transition: 0.5s;
}


.custom-input input {
  height: 16px;
}
.css-1pndypt-Input input{
  height: 30px!important;

}

.size-link{
  font-size: 18px;
}
.custom-checkbox-select .css-e56m7-control,
.custom-checkbox-select .css-1rjh3ff-control,
.custom-time-picker,
.css-1lb4uql-control,
.css-12zf3ro-control {
  min-height: 55px;
}

.tbl-th {
  padding: 20px 12px;
}

.tble-th-txt {
  font-size: 24px;
}

.tble-td-txt {
  font-size: 20px;
  color: #6e6e6e;
  padding: 20px 12px;
}

.css-14el2xx-placeholder {
  font-size: 16px !important;
  padding-left: 7px;
}

.main-body-section {
  padding: 40px 30px;
  background: #ffffff;
}

.small-title {
  font-size: 30px;
}

.btn-#c00000-border {
  border: 1px solid #0e61b4;
}

.btn-primary,
.btn-secondary {
  min-height: 55px;
  font-weight: bold;
}






.partnerlinks-textarea {
  height: auto;
}

.generate-links button {
  background: #32a445;
}

.btn-gray {
  background: #757575 !important;
  background-color: #757575 !important;
  transition: 0.5s;
  color: #ffff;
  cursor: pointer;
  min-height: 55px;
  border: 1px solid #666666;
  padding-left: 35px;
  padding-right: 35px;
  font-weight: bold;
  font-size: 20px;
}

.btn-gray:hover {
  background: #646464 !important;
  background-color: #646464 !important;
  transition: 0.5s;
  color: #ffff;
}



.font-bold {
  font-weight: bold;
}

.custom-tbl-td td {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
  text-align: left;
  padding: 15px;
}

.css-1s2u09g-control,
.css-1lb4uql-control,
.css-1pahdxg-control,
.css-12zf3ro-control {
  border-color: #65758b !important;
  border-radius: 0;
  padding: 7px 5px;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer !important;

}

.css-1s2u09g-control:hover {
  border-color: #65758b;
}

.css-e56m7-control {
  border-color: #64748b !important;
  padding: 7px 1px;
}

.css-1rjh3ff-control {
  border-color: #0e61b4 !important;
  padding: 7px 1px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-6j8wv5-Input input {
  height: 25px;
}


.css-6j8wv5-Input input,
.css-6j8wv5-Input input:after {
  border-color: none !important;
  outline: none !important;
  --tw-ring-color: none !important;
}

.css-6j8wv5-Input input[type="checkbox"] {
  height: unset;
}



/* Dash Board */
.corporate ,
.Individual{
  background-color: #9ebfe1;
}
.giving {
  background-color: #cedff0;
}
.Individual .value,
.Individual .para,
.corporate .value,
.corporate .para,
.giving .value,
.giving .para,
.asia .value,
.asia .para  {
  color: #333333;
 }


/* Custom Table start */

.table-bg {
  background: #acd7e5;
  background-color: #acd7e5;
}

.w-full .duplicate {
  background: #acd7e5;
  background-color: #ffffff;

}

.w-full .bg-duplicate {

  background-color: #fdf3f5 !important;
  color: #333333;
}


.overflow-x-auto .table .table-bg {
  color: #333333;
}

.middle .text-center tr {
  background-color: #ffffff;
}

.custom-tbody tr:nth-child(even) {
  background: #eff1f7;
  color: #333333;
}

.table-main thead tr th {
  color: #333333;

}

.table-main thead tr th .tble-th-txt {
  font-size: 20px;
}


.custom-action-btn {
  background: linear-gradient(0deg,
      rgba(199, 199, 199, 1) 0%,
      rgba(255, 255, 255, 1) 100%);
}

/* Custom Table End */

/* date picker */
.date-pickers {
  position: relative;



}

.date-pickers:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 0.5px;
  background: #757575;
  right: 54px;
  top: 0;
}


::-webkit-calendar-picker-indicator {
  background: url(../public/calender.svg) !important;
  background-position: center !important;
  background-size: contain !important;
}

.date-pickers {
  padding-right: 20px !important;

}

/* Pagination Start */
.pagination-btn {
  min-width: 350px;
}

.pagination-btn:hover {
  border-color: #0e61b4;
}

.pagination-btn svg path {
  fill: #757575;
  transition: 0.5s;
}

.pagination-btn:hover svg path {
  fill: #0e61b4;
  transition: 0.5s;
}

.footer-bg {
  background: #757575;
}

/* / Toggle  / */
.form-switch {
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 40px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.form-switch label {
  display: block;
  height: 1.25rem;
  cursor: pointer;
  overflow: hidden;
  border-radius: 9999px;
}

.form-switch label>span:first-child {
  position: absolute;
  display: block;
  border-radius: 9999px;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  right: 50%;
  transition: all 0.15s ease-out;
}

.form-switch input[type=checkbox]:checked+label>span:first-child {
  left: 22px;
}

.form-switch input[type=checkbox]:checked+label {
  background-color: #0e61b4;
}

.css-nnezq-menu {
  text-align: left !important;
}

#add-edit-strategic-member-modal .custom-input {
  text-align: left !important;
}

.table-main-over-flow-sec {
  overflow: inherit;
}

/* Pagination End */
@media only screen and (max-width: 1650px) {
  .small-title {
    font-size: 18px;
  }

  .tble-th-txt {
    font-size: 20px;
    color: #333333;
  }

  .tble-td-txt {
    font-size: 18px;
    color: #333333;
  }

  input,
  .btn-dropdown,
  select,
  textarea,
  .css-1lb4uql-control,
  .css-12zf3ro-control {
    height: 55px;
  }

  .custom-input input {
    height: 25px;
  }

  .header-txt {
    font-size: 14px;
  }

  .text-xl,
  .tble-th-txt {
    font-size: 16px !important;
  }

  .tble-td-txt {
    font-size: 14px !important;
  }

  .table-title-txt {
    font-size: 24px;
  }
  .size-link{
    font-size: 17px;
  }
}

@media only screen and (max-width: 1024px) {
  .size-link{
    font-size: 16px;
  }
}


.progresss-sec {
  background: #d5e8fc;
}

.progress {
  background: url(../public/loader.png);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  transition: 0.9s all;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}


/* checkbox */
/* AddEditAction Svg */
.action-btn .menu-svg :hover {
  fill: #0e61b4;
  transition: 0.5s;

}

.right-icon .menu-svg :hover {
  fill: #c00000;
  transition: 0.5s;
}



.fa-trash-o {
  font-size: 150%;
  opacity: 0.6;
  transition: 0.5s;
  color: #6e6e6e;
}

.fa-trash-o:hover {
  color: #c00000;
  transition: 0.5s;
  opacity: 1;
}

.fa-pencil {
  font-size: 150%;
  opacity: 0.6;
  transition: 0.5s;
  color: #6e6e6e;
}

.fa-pencil:hover {
  color: #0e61b4;
  transition: 0.5s;
  opacity: 1;
}

.colr-text {
  color: #03cafc;
}

.textarea-color {
  background-color: #eff1f7;
}

.div_size {
  width: 64% !important;
}

.align_text {
  text-align-last: right;
}

.page_font {
  height: 38px !important;
  padding-left: 1.90rem !important;
}


/* checkbox */


.loader_container {
  text-align: center;
  background: #ffffffbd;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

section {
  width: 30%;
  display: inline-block;
  text-align: center;
  min-height: 215px;
  vertical-align: top;
  margin: 1%;
  border-radius: 5px;

}

@media only screen and (max-width: 600px) {
  section {
    min-width: 350px;
  }
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-star {
  position: absolute;
  top: calc(50% - 12px);
}

/*LOADER-1*/

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #0e61b4;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #0e61b4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}



@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

#add-edit-strategic-member-modal .div_size {
  width: 70% !important;
}