.userSection-wrapper {
    justify-content: space-between;
    align-items: center;
    height: 100%;
    display: flex;
}

.userSection-wrapper .userName {
    color: #fff;
}

.userSection-wrapper .userName span {
    padding: 0px 1rem;
}

.userSection-wrapper .logoutButton {
    color: #fff;
    border-left: 1px solid var(--secondary-color);
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 2rem;
}

.userSection-wrapper .logoutButton span i{
    font-size: 2rem;
}