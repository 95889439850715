:root {
    --icon-width: 36px;
}

.close .navItems-wrapper {
    padding: 0;
}

.navItems-wrapper {
    flex: 1;
    padding: 0 1rem;
    position: relative;
}

.close .navItems-wrapper>ul {
    overflow: visible;
}

.navItems-wrapper>ul {
    width: 100%;
    margin: 0;
    overflow: hidden;
    left: 0;
    top: 0;
    background-color: #fff;
}

.navItems-wrapper>ul>li {
    padding: 0;
    padding: 1rem 0;
    white-space: nowrap;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: left;

}

.navItems-wrapper>ul>li:hover,
.navItems-wrapper>ul>li.active,
.navItems-wrapper>ul>li.active>.linkWrapper>a,
.navItems-wrapper>ul>li:hover>.linkWrapper>a {
    color: var(--primary-color) !important;
}

.close .navItems-wrapper>ul>li {
    display: flex;
    justify-content: center;
}

.close .navItems-wrapper>ul>li:hover {
    background-color: var(--primary-color);
    color: #fff !important;
}



.navItems-wrapper>ul>li>.linkWrapper {
    position: relative;
    display: inline-block;
    top: 0;
}

.close .navItems-wrapper>ul>li>.linkWrapper {
    position: absolute;
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: 2;
}


.close .navItems-wrapper>ul>li:hover>.linkWrapper {
    display: block;
    visibility: visible;
    text-align: left;
    opacity: 1;
    left: 100%;
    background-color: #fff;
    box-shadow: 9px 8px 20px 0px rgb(0 0 0 / 15%);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 1rem;
    min-height: 100%;
}





.navItems-wrapper>ul>li>span {
    width: var(--icon-width);
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.navItems-wrapper>ul>li>.linkWrapper>a {
    padding: 0.1rem 1rem;
    font-weight: bold;
    color: #717476;
    white-space: nowrap;
}




.navItems-wrapper>ul>li>.linkWrapper>a>.icon {}


.navItems-wrapper>ul>li>.linkWrapper>ul.hide {
    display: none;
}

.close .navItems-wrapper>ul>li>.linkWrapper>ul {
    display: none !important;
    background-color: var(--dark);
    min-height: 100%;
    padding: 1rem;
    box-shadow: 9px 8px 20px 0px rgb(0 0 0 / 15%);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.close .navItems-wrapper>ul>li>.linkWrapper:hover>ul {
    display: block !important;
    position: absolute;
    left: 100%;
    top: 0;
}

.navItems-wrapper>ul>li>.linkWrapper>ul {
    width: 100%;
    margin: 0;
}

.close .navItems-wrapper>ul>li>.linkWrapper>ul>.linkWrapper>li {
    padding: 0;
    margin: 0;
}

.navItems-wrapper>ul>li>.linkWrapper>ul>.linkWrapper>li {
    padding: 0;
    margin: 0.8rem 0;
}

.navItems-wrapper>ul>li>.linkWrapper>ul>.linkWrapper>li:last-child {
    margin-bottom: 0;
}


.navItems-wrapper>ul>li>.linkWrapper>ul>.linkWrapper>li>span {}

.close .navItems-wrapper>ul>li>.linkWrapper>ul>.linkWrapper>li>a {
    color: #fff;
    padding: 0;
}

.navItems-wrapper>ul>li>.linkWrapper>ul>.linkWrapper>li>a {
    padding: 0.1rem 3rem;
    color: #717476;
}